<template>
  <div>
    <p class="title">选择业务类型</p>
    <el-radio-group
        v-model="businessType"
        class="groupButton"
        @change="changeGroup"
    >
      <el-radio-button label="01">代账公司</el-radio-button>
      <el-radio-button label="02">个人代账</el-radio-button>
      <el-radio-button label="03">企业</el-radio-button>
    </el-radio-group>

    <div v-if="businessType == '01'">
      <el-form ref="mainForm1" :rules="rules" label-position="top" :model="groupFormData" label-width="8.2rem" class="groupForm">
        <el-form-item label="代账公司名称" prop="companyName">
          <el-autocomplete v-model="groupFormData.companyName" :fetch-suggestions="querySearchAsync" placeholder="请输入代账公司名称" @select="handleCompanySelect" :maxlength="100"
          ></el-autocomplete>
        </el-form-item>

        <el-form-item label="统一社会信用代码" prop="companyCreditCode" :rules="[{required:true,message:'请输入统一社会信用代码'},{pattern: '^[A-Z0-9]{18}$',message: '统一社会信用代码不合法',trigger: 'blur',},]">
          <el-input v-model="groupFormData.companyCreditCode" />
        </el-form-item>
        <el-form-item label="经营地">
          <el-cascader
              size="large"
              :options="addressOptions"
              v-model="groupFormData.companyRegion"
              :placeholder="'请选择经营地'"
              clearable
              style="width: 100%"
              @change="addressChange"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="详细地址" prop="companyAddress" :rules="[{min:1,max:50,message:'代账公司详细地址长度在 1 到 50 个字符'}]" placeholder="请输入详细地址">
          <el-input v-model="groupFormData.companyAddress" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="submitGroupBtn" @click="submitGroupForm('mainForm1')">立即创建</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div v-if="businessType == '02'">
      <el-form ref="mainForm2" :rules="rules" label-position="top" :model="groupFormData" label-width="8rem" class="groupForm">
        <el-form-item label="姓名" prop="personalName">
          <el-input v-model="groupFormData.personalName" maxlength="100" />
        </el-form-item>

        <el-form-item label="所在地区" prop="region">
          <el-cascader
              size="large"
              :options="addressOptions"
              v-model="groupFormData.personalRegion"
              :placeholder="'请选择所在地区'"
              style="width: 100%"
              clearable
              @change="addressChange"
          >
          </el-cascader>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" class="submitGroupBtn" @click="submitGroupForm('mainForm2')">立即创建</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div v-if="businessType == '03'">
      <el-form ref="mainForm3" :rules="rules" label-position="top" :model="groupFormData" label-width="8rem" class="groupForm">
        <el-form-item label="单位名称" prop="enterpriseName">
          <!-- <el-input v-model='groupFormData.name' placeholder="请输入单位名称"/> -->
          <el-autocomplete
              v-model="groupFormData.enterpriseName"
              :fetch-suggestions="querySearchAsync"
              placeholder="请输入单位名称"
              @select="handleEnterpriseSelect"
              :maxlength="100"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item label="统一社会信用代码" prop="enterpriseCreditCode" :rules="[{pattern: '^[A-Z0-9]{18}$',message: '纳税人识别号不合法',trigger: 'blur',},]">
          <el-input v-model="groupFormData.enterpriseCreditCode" placeholder="请输入统一社会信用代码"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="submitGroupBtn" @click="submitGroupForm('mainForm3')">立即创建</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { regionData, CodeToText } from "element-china-area-data";
export default {
  name: "companyAdd",
  components: {},
  data() {
    return {
      businessType: "01",
      addressOptions: regionData, //地区
      region: "",
      groupFormData: {
        companyName:'',
        companyCreditCode:'',
        companyRegion:'',
        companyAddress:'',

        personalName :'',
        personalRegion :'',

        enterpriseName :'',
        enterpriseCreditCode :'',
      },
      rules: {
        companyName: [
          { required: true, message: "请输入代账公司名称" },
          { min: 1, max: 50, message: "代账公司名称长度在 1 到 50 个字符" },
        ],
        personalName: [
          { required: true, message: "请输入姓名" },
          { min: 1, max: 50, message: "姓名长度在 1 到 50 个字符" },
        ],
        enterpriseName: [
          { required: true, message: "请输入单位名称" },
          { min: 1, max: 50, message: "单位名称长度在 1 到 50 个字符" },
        ],
      },
    };
  },
  methods: {
    querySearchAsync(queryString, cb) {
      if (!queryString) {
        cb();
        return;
      }
      this.$store.dispatch("GetTaxList", {
        keyword: queryString,
      })
          .then((res) => {
            if (res.success) {
              this.restaurants = res.data.map((item) => {
                //梳理数组结构
                return {
                  creditcode: item.creditcode,
                  value: item.name,
                };
              });
              var restaurants = this.restaurants;
              var results = queryString
                  ? restaurants.filter(this.createStateFilter(queryString))
                  : restaurants;

              clearTimeout(this.timeout);
              this.timeout = setTimeout(() => {
                cb(results);
              }, 500 * Math.random());
            } else {
              einvAlert.error("提示", res.msg==null?"未知异常":res.msg);
            }
          })
          .catch((err) => {
            err ? einvAlert.error("提示",err.msg||err.message||err) : einvAlert.error("提示",'未知异常')
          });
    },
    createStateFilter(queryString) {
      return (state) => {
        return (
            state.value.toLowerCase().indexOf(queryString.toLowerCase()) > -1
        );
      };
    },
    handleCompanySelect(item) {
      this.groupFormData.companyCreditCode = item.creditcode
    },
    handleEnterpriseSelect(item) {
      this.groupFormData.enterpriseCreditCode = item.creditcode
    },

    //
    addressChange(arr) {
      //省市联动
      console.log(arr);
      console.log(CodeToText[arr[0]], CodeToText[arr[1]], CodeToText[arr[2]]);
    },
    //
    changeGroup(val) {
      this.$refs["01"].resetFields();
      this.$refs["02"].resetFields();
      this.$refs["03"].resetFields();
    },
    //submit
    submitGroupForm(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          console.log(valid);
          let submitData = {}
          switch (this.businessType) {
            case '01':
              submitData = {
                name:this.groupFormData.companyName,
                taxNo:this.groupFormData.companyCreditCode,
                province:this.groupFormData.companyRegion[0],
                city: this.groupFormData.companyRegion[1],
                area: this.groupFormData.companyRegion[2],
                address: this.groupFormData.companyAddress,
              };
              break;
            case '02':
              submitData = {
                name:this.groupFormData.personalName,
                province:this.groupFormData.personalRegion[0],
                city: this.groupFormData.personalRegion[1],
                area: this.groupFormData.personalRegion[2],
              };
              break;
            case '03':
              submitData = {
                name:this.groupFormData.enterpriseName,
                taxNo:this.groupFormData.enterpriseCreditCode,
              };
              break;
            default:
              submitData = {}
              break;
          }
          let data = { businessType:this.businessType , creatorId: this.getCookies('eaycUserId'), callType:'1', ...submitData};
          this.isLoading = true;
          this.$store.dispatch("AddAgencyCompany", data).then(res => {
            if (res.success) {
              console.log(res);
              this.isLoading = false;
              einvAlert.success("提示", res.msg);
              this.$store.commit('SET_AGENCYCOMPANYID',res.data)
              this.$router.push('/home');
            } else {
              einvAlert.error("提示", res.msg);
              this.isLoading = false
            }
          }).catch(err => {
            this.isLoading = false
          })
        }
      });
    },
  },
  mounted() {
    // var date = new Date();
    // this.yearOptions = [];
    // this.tmpData.year = date.getFullYear();
    // this.tmpData.month = date.getMonth() + 1;
    // if (this.tmpData.month < 10) {
    //   this.tmpData.month = "0" + this.tmpData.month;
    // }
    // for (let i = date.getFullYear(); i >= date.getFullYear() - 10; i--) {
    //   this.yearOptions.push({
    //     value: i,
    //     label: i,
    //   });
    // }
    // this.yearOptions.unshift({
    //   value: date.getFullYear() + 1,
    //   label: date.getFullYear() + 1,
    // });
  },
};
</script>

<style lang="less" scoped>
.title {
  border-bottom: 1px solid #dcdcdc;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  position: relative;
  line-height: 70px;
  margin: 0;
}
.groupButton {
  margin: 30px auto 20px;
  display: table;
  /deep/ .el-radio-button__inner {
    min-width: 100px;
    // padding: 0.5rem 1.04rem;
  }
}
.groupForm {
  width: 420px;
  margin: 0 auto;
  .submitGroupBtn {
    margin: 20px auto 0;
    display: block;
  }
}
/deep/ .el-form--label-top .el-form-item__label {
  padding: 0;
}
/deep/ .el-form-item {
  margin-bottom: 0.7rem;
}
</style>>
